import React from "react";
import Image from "react-bootstrap/Image";
// import P1 from '../photos/P1.jpeg';
// import P2 from '../photos/P2.jpeg';
// import P3 from '../photos/P3.jpeg';
  
const Photo = (props) => {
	const {select, loadLightBox} = props

	if( select === "P1"){
		return (
						<Image fluid
							src="/P1.jpeg" onClick={loadLightBox}
						/>
		);
	}	else if( select === "P3") {
			return (
				<Image fluid
					src="/P3.jpeg" onClick={loadLightBox}
				/>
			);
	} 	else if( select === "P6") {
			return (
				<Image fluid
					src="/P6.jpeg" onClick={loadLightBox}
				/>
			);			
	} 	else if( select === "P12") {
			return (
				<Image fluid
				src="/P12.jpeg" onClick={loadLightBox}
			/>
		);			
	}	else if( select === "P13") {
			return (
				<Image fluid
					src="/P13.jpeg" onClick={loadLightBox}
				/>
			);
	} 	else if( select === "P14") {
			return (
				<Image fluid
					src="/P14.jpeg" onClick={loadLightBox}
				/>
			);			
	} 	else if( select === "P15") {
			return (
			<Image fluid
				src="/P15.jpeg" onClick={loadLightBox}
			/>
		);			
	} 	else if( select === "P16") {
			return (
			<Image fluid
				src="/P16.jpeg" onClick={loadLightBox}
			/>
		);			
	}	else if( select === "P17") {
			return (
				<Image fluid
					src="/P17.jpeg" onClick={loadLightBox}
				/>
			);
	} 	else if( select === "P18") {
			return (
				<Image fluid
					src="/P18.jpeg" onClick={loadLightBox}
				/>
			);			
	} 	else if( select === "P19") {
			return (
			<Image fluid
				src="/P19.jpeg" onClick={loadLightBox}
			/>
		);			
	} 	else if( select === "P22") {
			return (
			<Image fluid
				src="/P22.jpeg" onClick={loadLightBox}
			/>
		);			
	}
}

export default Photo;
