import React from 'react';

import "yet-another-react-lightbox/styles.css";
import "../styles/App.css";

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

//import { slides} from "../data/slides";

const LightBox = (props) => {

  const {slides, showLightBox, closeLightBox} = props
	console.log("showLightBox ", showLightBox)
  return (
	
      <Lightbox
        open={showLightBox}
        close={() => closeLightBox(false)}
        slides={slides}
        plugins={[Captions, Slideshow, Thumbnails]}
      />

  );
}

export default LightBox