import decode from 'jwt-decode';
//import { localStorageExt } from './component/localstorageext';

const RT_PLC_LOGIN = "/login.json" 
const RT_PLC_REFRESH = "/refresh.json"

export default class AuthService {
  // Initializing important variables
  constructor(domain) {

    this.domain = domain || 'http://localhost:8089'; // for Login, refresh
    console.log("domain", this.domain)
  }

  setDomain = (domain) => {
    if(domain)
      this.domain = domain 
  }

  
  login = async (username, password) => { 
    const data = {'username': username, 'password': password};
    
    const url = this.domain + RT_PLC_LOGIN; 
    const config = {
      method: 'POST',
      body: JSON.stringify(data)
    };
    console.log('async login: ', url, data)
    const res =  await this.fetchAuth(url, config);
    this.checkStatus(res);
    const jsonData = await res.json();
    console.log("Login Json Data", jsonData);
    this.setToken(jsonData.access_token);
    return res;
  };

  refresh = async () => { 
    console.log("refresh: ", this.domain)
    const url = this.domain + RT_PLC_REFRESH; 
    const res =  await this.fetchAuth(url);
    this.checkStatus(res);
    const jsonData = await res.json();
    console.debug("Refresh Json Data", jsonData);
    this.setToken(jsonData.access_token);
    return res;
  };

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }


  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      console.debug("decoded:", decoded);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return true;
    }
  }

	getUserFromToken() {
		try {
			const token = this.getToken(); // Get token from localstorage
      const decoded = decode(token);
			console.log('getUserFromToken: ', decoded.rls)
			return decode.rls	// egal, ob expired oder nicht. Das wird an anderer Stelle geprüft.
      } catch (err) {
      	return "";
    	}		
	}


  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('rt_token', idToken);
  }


  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('rt_token');
  }


  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('rt_token');
  }


  getProfile = () => { 
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }


  fetchAuth  = async (url, options) => {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    headers['Authorization'] = 'Bearer ' + this.getToken();
    const res = await fetch(url, {headers, ...options,});
    return res;
  };

  checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
  			/*
			  ...
				Error(message, options)
				...
				Parameters
					message Optional
    				A human-readable description of the error.
					options Optional
    				An object that has the following properties:
    				cause Optional
        			A property indicating the specific cause of the error. When catching and re-throwing an error with a more-specific or useful error message, this property can be used to pass the original error.
			*/
      var error = new Error(response.status, {'cause': response.statusText});
      error.response = response;
      throw error;
    }
  }
}