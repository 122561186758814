import React, { useState } from 'react';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import {German} from 'flatpickr/dist/l10n/de.js';
  
const DatePicker = (props) => {

	const {date, onChange, minDate, isDateExcuded} = props
	console.log("DatePicker: ", date, "min: ", minDate)

	return (
		<Flatpickr
			options={
				{
					minDate: minDate,
					locale: {
						...German
					},
					disableMobile: true,
					dateFormat: "d.m.Y",
					"disable": [
						(date) => {
								return isDateExcuded(date)
						}
					],					
				}
			}
			value={date}
			onChange={onChange}
	 	/>
    );

}

export default DatePicker;