import React from 'react';
import Image from "react-bootstrap/Image";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Okay from './okay_50_50.png';

const Reservierung = (props) => {

	const {dateCheckin, dateCheckout, resNumber, showOkay, onClickReserve} = props
	const uebernachtungen = dateCheckout.getDate() - dateCheckin.getDate()

	const [disabled, setDisabled] = React.useState(false);

	const PPN = 180 // Preis pro NAcht

	const onClick = () => {
		setDisabled(true)
		onClickReserve()
	}

	const center = {
		color: "red",
		justifyContent: 'center'
	}

	const scale = {
		transform: "scale(1)"
	}

	return (
		<>
		<Row className=" lh-1">
			<Col xs={9} className="text-start">
				{uebernachtungen} x Übernachtung zu 180,- €:
			</Col>
			<Col xs={3} className="text-end">
				{uebernachtungen*PPN},- €
			</Col>			
		</Row>
		<Row className=" lh-1">
			<Col xs={9} className="text-start">
				Kaution:
			</Col>
			<Col xs={3} className="text-end">
				250,- €
				<br />			
				---------
			</Col>						
		</Row>
		<Row className=" lh-1">
			<Col xs={12} className="text-end">
				{uebernachtungen*PPN+250},- €
			</Col>						
		</Row>
		<br />
		<Row>
			<p className=" lh-1 text-wrap">
				Sie können diese Konditionen für 3 Tage reservieren. Senden sie in der Zeit eine eMail mit folgenden Daten an: 
			</p>
			<p  class="text-decoration-underline" >
				<a href="#">asheepen@gmail.com</a>
			</p>
			<p>
				Name, Vorname
				<br />
				Anschrift
				<br />
				Telefon
				<br />
				Anreise / Abreise
				<br />
				Reservierungsnummer (s.u.)
			</p>
			<p>
				Sie erhalten in der Antwort den Mietvertrag.
			</p>	
		</Row>
		<Row className="align-items-center">
			<Col xs={4} md={2}>
				{disabled ? 
					<button disabled onClick={onClick} type="button" class="btn btn-secondary">reservieren</button>
					:
					<button onClick={onClick} type="button" class="btn btn-secondary">reservieren</button>					
				}
			</Col>
			<Col>
			{ showOkay && <Image style={scale}
        		src={Okay} 
      		/>}
			</Col>
			<Col className="text-end">
				{ showOkay && resNumber}
			</Col>
		</Row>
		</>	
	);
}

export default Reservierung;
