import * as React from "react";
import { useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "yet-another-react-lightbox/styles.css";
import "./styles/App.css";

import LightBox from "./components/LightBox"
import MenuBar from "./components/MenuBar"
import Photo from "./components/Photo"
import DatePicker from "./components/Calender"
import Reservierung from "./components/Reservierung"
import Impressum from "./components/Impressum"
import AuthService from "./AuthService";

import Form from 'react-bootstrap/Form';

const LOCAL_STORAGE_KEY = 'FEWO'
const Auth = new AuthService(global.BASE_URL);

export default function App() {

	const [showLightBox, setShowLightBox] = React.useState(false);
	const [showReservierung, setShowReservierung] = React.useState(false);
	const [showText, setShowText] = React.useState(true);
	const [showPreise, setShowPreise] = React.useState(false);

	const [dateCheckin, setDateCheckin] = React.useState(new Date());
	const [dateCheckout, setDateCheckout] = React.useState()
	const [minDate, setMinDate] = React.useState()

	const [checkin, setCheckin] = React.useState(false);
	const [checkout, setCheckout] = React.useState(false)
	const [persons, setPersons] = React.useState(0)	

	const [showOkay, setShowOkay] = React.useState(false);
	const [resNumber, setResNumber] = React.useState(12348765);

	const [showImpressum, setShowImpressum] = React.useState(false);

	const [error, setError] = React.useState();

	const _data = useRef();
	const _datesExcluded = useRef();

	useEffect(() => {
		 loadConfigFromServer()
		 const d = new Date()
		 d.setDate(d.getDate() + 7); 
		 setMinDate(d)
		 setDateCheckout(d)
	}, []);

	const handleExceptions = (error) => {

		if (error instanceof TypeError) {
			setError( "Can not connect to Server: " + error.message ); //
		} else if (error instanceof Error) {
			const errNumber = parseInt(error.message);
			if (errNumber === 401) {
				setError("The supplied registration token is invalid : " + error.message ); 
				//this.setState({ showLogin: true })
			} else if (errNumber >= 550 && errNumber <= 600) {
				// Flask Praetorian Errors
				setError( error.cause + ": " + error.message );
			} else {
				setError("Error checkConnectionStatus: " + error.message ); 
			}
		} else {
			setError( "Other-Error checkConnectionStatus: " + error.message ); 
		}					
	}

	const loadConfigFromServer = async () => {
		
		const url = global.BASE_URL + "/readconfig.json";
		var res = ""
		var data = {}
		try {

				console.debug("loadConfigFromServer ", url);
				res =  await Auth.fetchAuth(url);
				Auth.checkStatus(res)
				data = await res.json();
		   
		} catch (error) {
				handleExceptions(error)
		} finally {
			console.log("data : ", data)
			_data.current = data	
		}        
  }

	 /**
  * Save the Configutation to Flask Server
  */
	 const saveConfigToServer = async () => {
    const url = global.BASE_URL + "/writeconfig.json";
		
    var res = ""
    try {
      // if (Auth.loggedIn()) {
      //   const config = {
      //     method: 'POST',
      //     body: JSON.stringify(reservation)
      //   };
      //   res = await Auth.fetchAuth(url, config);
			// 	Auth.checkStatus(res)

      // } else {
      //   res = await Auth.refresh();
      // }
			const config = {
				method: 'POST',
				body: JSON.stringify(_data.current.bookings)
			};
			res = await Auth.fetchAuth(url, config);
			Auth.checkStatus(res)

    } catch (error) {

			this.handleExceptions(error)
    } finally {
      //
    }
  }

	const clickHandlerMenuBar = (eventKey) => {
    console.log("handleMenuClicked: ", eventKey);
    switch (eventKey) {
			case 'HOME':
        loadHome();
        break;
      case 'GALERIE':
        loadLightBox();
        break;
      case 'RESERVIERUNG':
        loadCalender();
        break;
			case 'PREISE':
					loadPreise();
					break;
			case 'IMPRESSUM':
				loadImpressum();
				break;
		}
	}

	const createDatesExcluded = () => {
	
		var fromDate
		var toDate
		var splitAn
		var splitAb
		var datesEx = []
		const data = _data.current

		//console.log("createDatesExcluded: ", _data.current )
		for (let i = 0; i < data.bookings.length; i++) {

			/*
				"datean": "2023-04-28T00:00:00.000Z" -> 20230428
			*/

			var range = {}
			splitAn = data.bookings[i].datean.split('T')	// 2023-04-28
		  fromDate = splitAn[0].split('-')							// ['2023', '04', '28']
	
			splitAb = data.bookings[i].dateab.split('T')
		  toDate = splitAb[0].split('-')
			//                       2023                             04                               28
			range.from = parseInt( fromDate[0] )*10000 + parseInt( fromDate[1] )*100  +  parseInt( fromDate[2] )
			range.to = parseInt( toDate[0] )*10000 + parseInt( toDate[1] )*100  +  parseInt( toDate[2] )

			datesEx.push(range)

		}
		_datesExcluded.current = datesEx
		return datesEx
	}

	const initReservation= () => {
		setShowImpressum(false)
		setShowOkay(false)
		setCheckin (new Date())
		setCheckout()
		setPersons(0)
		setShowReservierung(false)
	}

	const loadHome = () => {
		
		setShowLightBox(false)
		setShowText(true)
		setShowPreise(false)
		setShowImpressum(false)
		initReservation()
	}

	const loadImpressum = () => {
		setShowLightBox(false)
		setShowText(false)
		setShowReservierung(false)
		setShowImpressum(true)	
		setShowPreise(false)
	}

	const loadLightBox = () => {
		
		setShowLightBox(true)
		setShowReservierung(false)
	}

	const closeLightBox = () => {
		loadHome()
	}

	const loadCalender = () => {
		setShowImpressum(false)
		setShowReservierung(true)
		setShowText(false)
	}

	const loadPreise = () => {
		setShowImpressum(false)
		setShowPreise(true)
		setShowText(false)
	}

	const onChangeCheckin = ([date]) => {
		setDateCheckin(date)
		const d = new Date(date)
		d.setDate(d.getDate() + 7); 
		setMinDate(d)	
		setDateCheckout(d)	
		setCheckin(true)
	}

	const onChangeCheckout = ([date]) => {
		setDateCheckout(date)
		setCheckout(true)
	}

	const isDateExcuded = (date) => {
		
		const datesEx = createDatesExcluded()
		const dateInt =  parseInt( date.getFullYear() )*10000 + ( parseInt( date.getMonth() )+1 )*100  +  parseInt( date.getDate() )
								
		for (let i=0; i < datesEx.length; i++) {
			const range = new Object(datesEx[i])
			var nextRange = {}
			/*
				Die Belegungszeiträume / Buchungen ( ranges ) in [datesEx] sind aufsteigend sortiert.
			*/
			// Die letzte Buchnung hat keinen Nachfolger.
			if( i < datesEx.length-1) {
				nextRange = new Object(datesEx[i+1])
			}
			/*
				=> An- und Abreisetag gelten nicht als belegte Tage.
						Ausnahme: An diesem Tag (date) findet ein direkter Belegungswechsel statt.
			*/
			if( range.from < dateInt ) {
				if( dateInt < range.to ){
					return true
				}	
			}
			if( dateInt === range.to ){
				// ein Abreisetag ...
				if( range.to === nextRange.from) {
					// Belegungswechsel
					return true
				}	else {
					// nur Abreise, kein neuer Fast
					return false
				}	
				return true
			}	
		}
		return false
	}

	const getNextFreeRange = (date) => {
		/*
			"date" ist ein belegter Tag. Anreise und Abreise sind keine belegten Tage.
		*/

	}

	const onSelectPersons = (countPesrsons) => {
		setPersons(countPesrsons)
	}

	const isEverythingSelected = () => {
	
		if( showReservierung && checkin  && persons != 0)
			return true
		return false
	}

	const onClickReserve = () => {
		setShowOkay(true)

		// _data.bookings[] updaten und sortieren
		updateAndSort()
		// Reservierungen / Buchungen zum Server schreiben
		saveConfigToServer()

	}

	const toISOStringWithTimezone = date => {
		const tzOffset = -date.getTimezoneOffset();
		const diff = tzOffset >= 0 ? '+' : '-';
		const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate()) +
			'T' + pad(date.getHours()) +
			':' + pad(date.getMinutes()) +
			':' + pad(date.getSeconds()) +
			diff + pad(tzOffset / 60) +
			':' + pad(tzOffset % 60);
	};

	const updateAndSort = () => {

		// ISO 8601 mit korrigiertem Time Zone offset, sonst wird aus 
		// Fri Apr 14 2023 00:00:00 GMT+0200 (Central European Summer Time) -> 2023-04-13T22:00:00:00.000Z
		// und damit wird für die in diesem Programm verwendete Logik aus dem 14.04 der 13.04.

		// Eigentlich ist das ein "falsche" Datum / Zeit, wenn man durchgängig den Prototype Date() nutzen würde.
		// Dieses Programm verwerdet den ISO 8601 String allerdings wie einen nativen String und wandelt ihn für
		// die eigne Logik nicht in den Prototype Date() um. 

		const offset = (new Date()).getTimezoneOffset()

		const tzan = new Date(dateCheckin.getTime() - offset*60*1000)
		const tzab = new Date(dateCheckout.getTime() - offset*60*1000)
		const rn = Math.floor(Math.random() * 100000)

		const reservation = {
			
			anab: false,
			datebooked: new Date().toISOString(),
			datean: tzan.toISOString(),
			dateab: tzab.toISOString(),
			preisprotag: 180,
			type: "reserved",
			resnumber: rn
		}

		setResNumber(rn)

		var bookings = _data.current.bookings
		bookings.push(reservation)

		const dans = []
		const tmp = []
		// const  dateAnMin = new Date(bookings[0].datean)	// initialisieren mit dem 1.ten Eintrag
		// var dateAnLoop
		// var idxMin = 0

		for(const booking of bookings) {
			dans.push(booking.datean)
		}
		dans.sort()

		for(const dan of dans) {
			for (let b = 0; b < bookings.length; b++) {
				
				if( dan === bookings[b].datean ){
					tmp.push(bookings[b])
				}
			}
		}
		_data.current.bookings = tmp
	}

	const marginBottom10 = {
		"marginBottom": "10px"
	}

	const marginBottom30 = {
		"marginBottom": "30px"
	}

	const slides = [
		{ src: "/P1.jpeg" },
		{ src: "/P3.jpeg" },
		{ src: "/P6.jpeg" },
		{ src: "/P12.jpeg" },
		{ src: "/P13.jpeg" },
		{ src: "/P14.jpeg" },
		{ src: "/P15.jpeg" },
		{ src: "/P17.jpeg" },
		{ src: "/P18.jpeg" },
		{ src: "/P19.jpeg" },
		{ src: "/P22.jpeg" },
		// ...
	];

  return (
    <Container>
				<Row>
					<Col>
						<MenuBar clickHandlerMenuBar={clickHandlerMenuBar}></MenuBar>
					</Col>
				</Row>
			{!showImpressum && <Container>
				{ !showReservierung ? <Row className="align-items-stretch">
				<Col xs={12} md={6} className="d-flex">
					<Photo  select={"P1"} loadLightBox={loadLightBox}></Photo>
				</Col>
				{/* Hidden only on xs 	.d-none .d-sm-block */}
				<Col className="d-flex flex-column justify-content-between ">
					<Row className="d-none d-sm-block" >
						<Photo select={"P22"} loadLightBox={loadLightBox}></Photo>
					</Row>
					<Row className="d-none d-sm-block" >
						<Photo select={"P15"} loadLightBox={loadLightBox}></Photo>
					</Row>
				</Col>
				<Col className="d-flex flex-column justify-content-between">
					<Row className="d-none d-sm-block" >	
							<Photo select={"P14"} loadLightBox={loadLightBox}></Photo>
					</Row>
					<Row className="d-none d-sm-block" >	
							<Photo select={"P3"} loadLightBox={loadLightBox}></Photo>
					</Row>
				</Col>		
			</Row> : <>
			<Row className="align-items-stretch">
				{/* Visible only on xs	 .d-block .d-sm-none */}
				<Col xs={8} className="d-flex d-block d-sm-none">				
					<Photo select={"P1"} loadLightBox={loadLightBox}></Photo>
				</Col>
				<Col xs={4} className="d-flex flex-column justify-content-between d-block d-sm-none">
					<Row>
						Anreise
						<DatePicker date={dateCheckin} onChange={onChangeCheckin} minDate={"today"} isDateExcuded={isDateExcuded}  />
					</Row>
					<Row>
						Abreise
						<DatePicker date={dateCheckout} onChange={onChangeCheckout} minDate={minDate} isDateExcuded={isDateExcuded}/>					
					</Row>
					<Row>
						<Form.Select value={persons} aria-label="Person select" onChange={(e) => onSelectPersons(e.target.value)}>
							<option>Personen</option>
							<option value="1">Eine</option>
							<option value="2">Zwei</option>
						</Form.Select>													
					</Row>
				</Col>
				{/* Hidden only on xs 	.d-none .d-sm-block */}
				<Col md={6} className="d-flex d-none d-sm-block">
					<Photo select={"P1"} loadLightBox={loadLightBox}></Photo>
				</Col>
				<Col className="d-flex flex-column justify-content-between ">
					<Row className="d-none d-sm-block" >
						<Photo select={"P2"} loadLightBox={loadLightBox}></Photo>
					</Row>
					<Row className="d-none d-sm-block" >
						<Photo select={"P1"} loadLightBox={loadLightBox}></Photo>
					</Row>
				</Col>
				<Col className="d-flex flex-column justify-content-between">
					<Row className="d-none d-sm-block" >	
							<Photo select={"P1"} loadLightBox={loadLightBox}></Photo>
					</Row>
					<Row className="d-none d-sm-block" >	
							<Photo select={"P3"} loadLightBox={loadLightBox}></Photo>
					</Row>
				</Col>					
			</Row>
			<Row className="align-items-sm-end">
				<Col  className="d-flex d-none d-sm-block">
				<br />
					Anreise
					<br />	
					<DatePicker date={dateCheckin} onChange={onChangeCheckin} minDate={"today"} isDateExcuded={isDateExcuded}  />
				</Col>
				<Col  className="d-flex d-none d-sm-block">
	
					Abreise
					<br />	
					<DatePicker date={dateCheckout} onChange={onChangeCheckout} minDate={minDate} isDateExcuded={isDateExcuded}/>	
				</Col>
				<Col className="d-flex d-none d-sm-block">
				
						<Form.Select value={persons} aria-label="Person select" onChange={(e) => onSelectPersons(e.target.value)}>
							<option>Personen</option>
							<option value="1">Eine</option>
							<option value="2">Zwei</option>
						</Form.Select>		
				</Col>
			</Row>
			</>
			}
			</Container>}
			<div style={marginBottom30}></div>
      { showText && <Row>
				<div className="col">
					<p class="font-weight-bold">Studio über den Dächern der Altstadt</p>
					<p class="font-weight-normal">Modern ausgestattete Dachgeschosswohnung mit kleinem Balkon einmalig gelegen an der Fußgängerzone der Altstadt, super zentral und trotzdem ruhig. Wenige Minuten zu Fuß zum Bahnhof, zum Hafen, zur Schweizer Grenze.

Die Wohnung wurde 2019 renoviert und verfügt über ein Stapelbett (2 x 1,90m x 0,90m), Einbauküche (Miele), Einbauschränke, Holzfußböden, einen Smart-TV mit Sat-Anschluss und  WLAN-Internetanschluss. Zusätzlicher Stauraum im Dachspitz und seitlich dem Balkon.

Das hochwertige Badezimmer ist mit einer Dusche mit Regenbrause und WC/BD Kombi ausgestattet. Die Waschmaschine Miele „Wash and Dry“ befindet sich ebenfalls im Bad.

Aufgrund der Erstvermietung sind einige Dinge noch nicht auf Bildern zu sehen.

Einfach einziehen und wohlfühlen.</p>
{/* 					<h5>20 px hoch.</h5>
					<p class="font-weight-light">Light weight text.</p>
					<p class="font-italic">Italic text.</p> */}
					{/* <p class="text-justify">Ambitioni dedisse scripsisse iudicaretur. Cras mattis iudicium purus sit amet fermentum. Donec sed odio operae, eu vulputate felis rhoncus. Praeterea iter est quasdam res quas ex communi. At nos hinc posthac, sitientis piros Afros. Petierunt uti sibi concilium totius Galliae in diem certam indicere. Cras mattis iudicium purus sit amet fermentum.</p> */}
				</div> 
			</Row>}
      { showPreise && <Row>
				<div className="col">
					{/* <p class="font-weight-bold">Studio über den Dächern der Altstadt </p> */}
					<p class="font-italic">bis 30.09.2025 belegt</p>
					<p class="font-weight-normal">
<tr> 
				<td> 1 - 3 Monate:</td>
				<td>€1900</td>
</tr>
<tr> 
				<td> 3 - 6 Monate:</td>
				<td>€1750</td>
</tr>
<tr> 
				<td> 6 - 12 Monate:</td>
				<td>€1500</td>
</tr>
<tr> 
				<td>12 - 24 Monate:</td>
				<td>€1300</td>
</tr>
Miete incl. aller Nebenkosten außer Betriebskosten. 
Diese werden gemäß Verbrauch zu den Kosten des Energieversorgers abgerechnet.
<br />Kaution: €3000,-<br />
<br />Kontakt: 0170 2471198 oder <a href="mailto:asheepen@gmail.com">asheepen@gmail.com</a><br />
</p>
				</div> 
			</Row>}
			<Container>	
			{isEverythingSelected() && <Reservierung dateCheckin={dateCheckin} dateCheckout={dateCheckout} 
																								resNumber={resNumber}  showOkay={showOkay}  onClickReserve={onClickReserve} />}
			</Container>
			{showImpressum && <Impressum />}
			<LightBox slides={slides} showLightBox={showLightBox} closeLightBox={closeLightBox}></LightBox>
			</Container>
	);

}
 

		// // im Original[] nach dem Objekt mit dem kleinsten "datean" suchen und dessen Index merken ...
		// for (let b = 0; b < bookings.length; b++) { 
		// 	// Übergehen, wenn schon umkopiert
		// 	if(!bookings[b].done) {
		// 		dateAnLoop = new Date(bookings[b].datean)
		// 		if( dateAnMin.getTime() <= dateAnLoop.getTime() ){
		// 			// ... nichts tun ( gleich sollte nicht vorkommen)
		// 		} else {
		// 			// diesen Index merken. Das bis hierhin "kleinste"
		// 			idxMin = b
		// 		}
		// 		// ... dieses Objekt nach tmp[] umkopieren ...
		// 		tmp.push(bookings[idxMin])
		// 		// ... und im Original markieren, damit es beim nächsten Durchlauf übergangen wird
		// 		// Anm.: So muß man im Original keine Elemente des Arrays innerhalb des loops löschen, re-indexing ...
		// 		bookings[idxMin].done = true	
		// 	}
		// }
