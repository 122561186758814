import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import 'bootstrap/dist/css/bootstrap.min.css';

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

//KN global.BASE_URL = "https://192.168.178.31:8445";
global.BASE_URL = "https://192.168.2.200:8445";

global.show_login_user = false;
global.show_load_config_server = false;


if (process.env.NODE_ENV === "production")
{
  console.debug = function() {};
  console.info = function() {};
  console.log = function() {};
} else {
  global.BASE_URL = "http://192.168.2.200:8087";

  global.show_login_user = true;
  global.show_load_config_server = true;

}

root.render(
  <StrictMode>

      <App/>

  </StrictMode>
);
