import React from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const fs20 = {
	"font-size": 20
}

const MenuBar = props => {
	const {clickHandlerMenuBar} = props
  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
			<Navbar.Brand href="#"><h4>Wohnen auf Zeit in Konstanz</h4></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="justify-content-end flex-grow-1 pe-3" onSelect={clickHandlerMenuBar}>

					<Nav.Link eventKey="HOME">Home</Nav.Link>
					{/* <Nav.Link eventKey="RESERVIERUNG">Reservierung</Nav.Link> */}
					<Nav.Link eventKey="PREISE">Preise</Nav.Link>
					<Nav.Link eventKey="GALERIE">Galerie</Nav.Link>
					<Nav.Link eventKey="IMPRESSUM">Impressum</Nav.Link>
					{/* <NavDropdown title="Galerie" id="file-nav-dropdown">
						<NavDropdown.Item eventKey={"OPEN_FILE_CFG"}>Open Config</NavDropdown.Item>
						<NavDropdown.Item eventKey={"OPEN_FILE_CM"}>Open CentralMap</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item eventKey={"EXPORT_CFG_FILE"}>Export Config</NavDropdown.Item>
					</NavDropdown> */}
				</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MenuBar;